import {createTheme} from "@mui/material/styles";

const typography = {
  fontFamily: "\"Lato\", sans-serif",
  h1: {
    fontFamily: "\"Heebo\", sans-serif",
    fontSize: "2.5rem",
    fontWeight: 700
  },
  h2: {
    fontFamily: "\"Heebo\", sans-serif",
    fontSize: "1.8rem",
    fontWeight: 700
  },
  h3: {
    fontFamily: "\"Heebo\", sans-serif",
    fontSize: "1.5rem",
    fontWeight: 700
  },
  h4: {
    fontSize: "1.2rem",
    fontFamily: "\"Heebo\", sans-serif",
    fontWeight: 700
  },
  h5: {
    fontFamily: "\"Heebo\", sans-serif",
    fontWeight: 700
  },
  h6: {
    fontFamily: "\"Heebo\", sans-serif",
    fontWeight: 700
  },
};

const components = (theme) => {
  const isLightTheme = theme.palette.mode === "light";

  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(50px) " + (isLightTheme ? "brightness(200%)" : "brightness(100%)"),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: isLightTheme ? "#222222" : "#FEFEFE",
          "&:hover": {
            color: isLightTheme ? theme.palette.primary.main + " !important" : theme.palette.primary.main + " !important",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        }
      },
      variants: [
        {
          props: {variant: "contained-clear"},
          style: {
            backdropFilter: "blur(50px) " + (isLightTheme ? "brightness(200%)" : "brightness(100%)"),
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            "&:hover": {
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
            },
            "@media (max-width:599.95px)": {
              "&:hover": {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary
              },
              "&:active": {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
              },
            },
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input[type=number]": {
            WebkitAppearance: "none",
            MozAppearance: "textfield",
            margin: 0,

            "&::-webkit-outer-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "&::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
          },
        },
      },
    },
  }
};

let lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      ultraLight: "#DCA893",
      light: "#d0896d",
      main: "#C36A46",
      dark: "#af4b24",
      contrastText: "#FEFEF0"
    },
    secondary: {
      ultraLight: "#a1b2c3",
      light: "#4d7495",
      main: "#3E5C76",
      dark: "#385268",
      contrastText: "#FEFEF0"
    },
    tertiary: {
      ultraLight: "#c2d3b7",
      light: "#BBDEA6",
      main: "#728B59",
      dark: "#5f744b",
      contrastText: "#FEFEF0"
    },
    background: {
      paper: "#f5f5f5CC",
      default: "#FEFEF0CC"
    }
  },
  typography,
});
lightTheme.components = components(lightTheme);

let darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      ultraLight: "#d6beac",
      light: "#ca917a",
      main: "#C36A46",
      dark: "#95563c",
      contrastText: "#FEFEF0"
    },
    secondary: {
      ultraLight: "#8bd1e3",
      light: "#6788aa",
      main: "#516c88",
      dark: "#445b71",
      contrastText: "#FEFEF0"
    },
    tertiary: {
      ultraLight: "#c2d3b7",
      light: "#BBDEA6",
      main: "#728B59",
      dark: "#5c7344",
      contrastText: "#FEFEF0"
    },
    quaternary: {
      light: "#8f8f8f",
      main: "#606060",
      dark: "#444444",
      contrastText: "#FEFEF0"
    },
    background: {
      paper: "#252525BB",
      default: "#121212BB"
    }
  },
  typography,
});
darkTheme.components = components(darkTheme);

export {
  lightTheme,
  darkTheme
};

import React, {useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import {debounce} from "@mui/material/utils";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";


const AdresseSelect = ({
                         onSelectAdresse,
                         distanceMAutourAdresse,
                         onSelectDistanceMAutourAdresse,
                         fieldProps,
                         size = "normal",
                         disabled = false,
                         commune
                       }) => {

  useEffect(() => {
    setInputValue("");
    setValue(null);
    onSelectAdresse(null);
  }, [commune]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = useState(false);

  const handleSliderChange = (event, newValue) => {
    onSelectDistanceMAutourAdresse(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const PopupOpen = Boolean(anchorEl);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        axios.get("/adresses", {
          params: {
            recherche: request.input,
            id_commune: commune?.id_commune,
          }
        }).then(function(response) {
          const responseData = response.data;
          callback(responseData);
        }).catch(function(error) {
          console.log(error);
        })
      }, 400),
    [commune],
  );

  React.useEffect(() => {
    let active = true;
    setOpen(false);

    if (inputValue === "" || inputValue.replace(/\s+/g, '').length < 3) {
      setOptions([]);
      return undefined;
    }

    fetch({input: inputValue}, (results) => {
      if (active) {
        setOpen(true);
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue]);

  return (
    <>
      <Autocomplete
        {...fieldProps}
        open={open}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions={false}
        isOptionEqualToValue={(option, value) => {
          return option.id_adresse === value.id_adresse;
        }}
        filterOptions={(options, state) => options}
        value={value}
        onBlur={() => setOpen(false)}
        disableClearable
        openOnFocus={false}
        noOptionsText="Aucun résultat"
        disabled={disabled}
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelectAdresse(newValue);
          setOpen(false);
        }}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            setInputValue(newInputValue);
          }
        }}
        getOptionLabel={(option) => option.adresse}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box sx={{
                  display: "flex",
                  width: 44
                }}>
                  <LocationOnIcon sx={{color: "text.primary"}}/>
                </Box>
                <Box sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word"
                }}>
                  <Box
                    key={option.nom + "-" + option.code_postal}
                    component="span"
                    sx={{fontWeight: "bold"}}
                  >
                    {option.numero} {option.nom_voie}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {option.code_postal_commune} {option.nom_commune}
                  </Typography>
                </Box>
              </Stack>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"1 Avenue des Champs-Élysées"}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
              },
              "& .MuiAutocomplete-inputRoot": {
                padding: (size === "small" ? "5px 20px 5px 20px" : "9px 30px 9px 30px"),
              }
            }}
            fullWidth
            disabled={disabled}
            slotProps={{
              input: {
                ...params.InputProps,
                autoCorrect: "off",
                autoComplete: "off",
                spellCheck: "false",
                autoCapitalize: "none",
                inputMode: "text",
                sx: {
                  fontSize: (size === "small" ? "80%" : "100%"),
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button color="primary" disableElevation onClick={handleClick} sx={{
                      width: "80px",
                      fontSize: "80%",
                    }}>
                      {distanceMAutourAdresse} m autour
                    </Button>
                  </InputAdornment>
                ),
              }
            }}
          />
        )}
      />

      <Popover
        open={PopupOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{
          padding: "3px 20px 1px 20px"
        }}>
          <Slider
            value={distanceMAutourAdresse}
            onChange={handleSliderChange}
            aria-labelledby="distance-slider"
            valueLabelDisplay="off"
            min={0}
            max={100}
            color="secondary"
            sx={{
              width: "100px",
              "& .MuiSlider-thumb": {
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&::before": {
                  display: "none",
                },
              },
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default AdresseSelect;

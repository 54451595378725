import React, {createContext, useContext, useState} from "react";
import axios from "axios";
import GeoJSON from "ol/format/GeoJSON";
import {AppContext, PAGE_ACCUEIL, PAGE_EXPLORATION_COMMUNE} from "./AppProvider";
import {MapContext} from "./MapProvider";


export const SearchContext = createContext();

export const TYPE_RECHERCHE_SURFACE = "SURFACE";
export const TYPE_RECHERCHE_ADRESSE = "ADRESSE";
export const TYPE_RECHERCHE_REFERENCE_CADASTRALE = "REFERENCE_CADASTRALE";
export const TYPE_RECHERCHE_EXPLORATION_COMMUNE = "EXPLORATION_COMMUNE";

const SearchProvider = ({children}) => {
  const {
    setLoading,
    openResultPanel,
    getWaitTokenAndWait,
    setAlertMessage,
    page,
  } = useContext(AppContext);

  let typeRechercheComputed;
  if (page === PAGE_ACCUEIL && options?.typeRecherche === TYPE_RECHERCHE_ADRESSE) {
    typeRechercheComputed = TYPE_RECHERCHE_ADRESSE;
  } else if (page === PAGE_ACCUEIL && options?.typeRecherche === TYPE_RECHERCHE_REFERENCE_CADASTRALE) {
    typeRechercheComputed = TYPE_RECHERCHE_REFERENCE_CADASTRALE;
  } else if (page === PAGE_ACCUEIL && options?.typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE) {
    typeRechercheComputed = TYPE_RECHERCHE_EXPLORATION_COMMUNE;
  } else if (page === PAGE_EXPLORATION_COMMUNE) {
    typeRechercheComputed = TYPE_RECHERCHE_EXPLORATION_COMMUNE;
  } else {
    typeRechercheComputed = TYPE_RECHERCHE_SURFACE;
  }

  const [typeRecherche, setTypeRecherche] = useState(typeRechercheComputed);

  React.useEffect(() => {
    if(page !== PAGE_ACCUEIL) {
      return;
    }
    let url;
    const currentParams = new URLSearchParams(window.location.search);

    if (typeRecherche === TYPE_RECHERCHE_SURFACE) {
      url = "/cadastre/recherche/par_surface";
    } else if (typeRecherche === TYPE_RECHERCHE_ADRESSE) {
      url = "/cadastre/recherche/par_adresse";
    } else if (typeRecherche === TYPE_RECHERCHE_REFERENCE_CADASTRALE) {
      url = "/cadastre/recherche/par_reference_cadastrale";
    } else if (typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE) {
      url = "/cadastre/explorer";
    }
    const newUrl = currentParams.toString() ? `${url}?${currentParams.toString()}` : url;

    window.history.pushState(null, null, newUrl);
  }, [typeRecherche]);

  //Recherche par surface
  const [commune, setCommune] = useState(options?.commune !== undefined && options?.commune !== null ? {...options.commune} : null);

  React.useEffect(() => {
    if(page !== PAGE_ACCUEIL) {
      return;
    }
    const url = window.location.pathname;
    const currentParams = new URLSearchParams(window.location.search);
    if (commune !== undefined && commune !== null) {
      currentParams.set("commune", commune.code_insee);
    } else {
      currentParams.delete("commune");
    }
    const newUrl = currentParams.toString() ? `${url}?${currentParams.toString()}` : url;
    window.history.pushState(null, null, newUrl);
  }, [commune]);

  const [distanceKmAutourCommune, setDistanceKmAutourCommune] = useState(0);
  const [surface, setSurface] = useState("");
  const [toleranceSurfacePourcentage, setToleranceSurfacePourcentage] = useState(0);
  const [tenterRegrouperParcelles, setTenterRegrouperParcelles] = useState(false);

  //Recherche par adresse
  const [adresse, setAdresse] = useState(null);
  const [distanceMAutourAdresse, setDistanceMAutourAdresse] = useState(10);

  //Recherche par reference cadastrale
  //commune
  const [idParcelle, setIdParcelle] = useState(null);

  const {
    setFeatures,
    setSelectedFeature,
  } = useContext(MapContext);

  const formIsValidOrGetErrors = () => {
    if (typeRecherche === TYPE_RECHERCHE_SURFACE) {
      if (commune === null) {
        return "Veuillez sélectionner une commune dans la liste";
      } else if (surface <= 0) {
        return "Veuillez saisir une surface supérieure à zéro";
      } else if (surface > 100000) {
        return "Veuillez saisir une surface inférieure à 100000";
      }
      return true;
    } else if (typeRecherche === TYPE_RECHERCHE_ADRESSE) {
      if (adresse === null) {
        return "Veuillez sélectionner une adresse dans la liste";
      }
      return true;
    } else if (typeRecherche === TYPE_RECHERCHE_REFERENCE_CADASTRALE) {
      if (idParcelle === null) {
        return "Veuillez sélectionner une parcelle dans la liste";
      }
      return true;
    } else if (typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE) {
      if (commune === null) {
        return "Veuillez sélectionner une commune dans la liste";
      }
      return true;
    }
    return false;
  }

  const doExplore = () => {
    document.location = `${(commune.url_commune).replace(/[^\/a-zA-Z0-9-_]/g, "")}`;
  }

  const traceAction = (action, data) => {
    if (typeof umami != "undefined") {
      umami.track(action, data);
    }
  }

  const doSearch = async () => {
    if (formIsValidOrGetErrors() !== true) {
      setAlertMessage(formIsValidOrGetErrors());
      setTimeout(() => {
        setAlertMessage(null);
      }, 5000);
      return;
    }


    setLoading(true);

    try {
      let response;
      if (typeRecherche === TYPE_RECHERCHE_SURFACE) {
        traceAction("Lancement recherche par surface", {commune: commune.nom});
        const waitToken = await getWaitTokenAndWait();
        response = await axios.get("/parcelles_by_surface_and_commune", {
          params: {
            wait_token: waitToken,
            id_commune: commune?.id_commune,
            distance_km_autour_commune: distanceKmAutourCommune,
            surface: surface,
            tolerance_surface_pourcentage: toleranceSurfacePourcentage,
            tenter_regrouper_parcelles: tenterRegrouperParcelles
          }
        });
      } else if (typeRecherche === TYPE_RECHERCHE_ADRESSE) {
        traceAction("Lancement recherche par adresse", {commune: commune.nom});
        const waitToken = await getWaitTokenAndWait();
        response = await axios.get("/parcelles_by_adresse", {
          params: {
            wait_token: waitToken,
            id_adresse: adresse?.id_adresse,
            distance_m_autour_adresse: distanceMAutourAdresse
          }
        });
      } else if (typeRecherche === TYPE_RECHERCHE_REFERENCE_CADASTRALE) {
        traceAction("Lancement recherche par référence cadastrale", {commune: commune.nom});
        const waitToken = await getWaitTokenAndWait();
        response = await axios.get("/parcelle", {
          params: {
            wait_token: waitToken,
            id_parcelle: idParcelle,
          }
        });
      } else if (typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE) {
        traceAction("Lancement exploration commune", {commune: commune.nom});
        response = await axios.get("/parcelles_by_surface_and_commune", {
          params: {
            page: PAGE_EXPLORATION_COMMUNE,
            id_commune: commune?.id_commune,
          }
        });
      }
      const responseData = response.data;
      const data = JSON.parse(responseData[0].data) || [];

      let allFeatures = [];

      // Parcourir tous les éléments du tableau de données
      data.forEach(item => {
        const feature = new GeoJSON().readFeature(item.geom, {
          featureProjection: "EPSG:3857", // Projection de la carte
        });
        if (item.is_regroupee) {
          let parcellesFilles = [];
          item.parcelles.forEach(parcelle => {
            const subFeature = new GeoJSON().readFeature(parcelle.geom, {
              featureProjection: "EPSG:3857", // Projection de la carte
            });
            subFeature.setProperties(parcelle);
            parcellesFilles.push(subFeature);
          });
          item.parcelles = parcellesFilles;
        }
        feature.setProperties(item);

        allFeatures.push(feature);
      });

      setFeatures(allFeatures);
      if (allFeatures.length === 1) {
        setSelectedFeature(allFeatures[0]);
      }
      setLoading(false);
      openResultPanel(true);
    } catch(error) {
      setAlertMessage("Une erreur s'est produite. Votre recherche a peut-être générée trop de résultat. Essayez de limiter votre recherche.");
      setTimeout(() => {
        setAlertMessage(null);
      }, 5000);
      setLoading(false);
      traceAction("Erreur recherche");
    }
  };

  return <SearchContext.Provider value={{
    typeRecherche,
    setTypeRecherche,
    commune,
    setCommune,
    distanceKmAutourCommune,
    setDistanceKmAutourCommune,
    surface,
    setSurface,
    toleranceSurfacePourcentage,
    setToleranceSurfacePourcentage,
    tenterRegrouperParcelles,
    setTenterRegrouperParcelles,
    adresse,
    setAdresse,
    distanceMAutourAdresse,
    setDistanceMAutourAdresse,
    idParcelle,
    setIdParcelle,
    doSearch,
    doExplore,
    formIsValidOrGetErrors
  }}>
    {children}
  </SearchContext.Provider>
};

export default SearchProvider;

import React, {useContext} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {AppContext} from "./context/AppProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Link from "@mui/material/Link";

const MentionsLegalesPopup = () => {
  const {
    mentionsLegalesOpen,
    setMentionsLegalesOpen,
  } = useContext(AppContext);

  return (
    <Dialog
      open={mentionsLegalesOpen}
      onClose={() => setMentionsLegalesOpen(false)}
      sx={{
        "& .MuiDialog-paper": {
          width: "800px",
          maxWidth: "80%",
          maxHeight: "80%",
        },
      }}
    >
      <DialogActions>
        <Button
          onClick={() => {
            setMentionsLegalesOpen(false);
          }}
          sx={{
            height: "40px",
            borderRadius: "20px",
            minWidth: "40px",
          }}
        >
          <FontAwesomeIcon icon={faXmark}/>
        </Button>
      </DialogActions>
      <DialogTitle>
        <Button
          variant="contained"
          sx={{marginRight: "20px"}}
          href="#cgu"
        >
          Conditions Générales d'Utilisation (CGU)
        </Button>
        <Button variant="contained" href="#mentions-legales">
          Mentions Légales
        </Button>
      </DialogTitle>
      <DialogContent>
        <Typography id="cgu" sx={{paddingTop: "30px"}} variant="h1">
          Conditions Générales d’Utilisation (CGU)
        </Typography>
        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 1 : Objet et présentation du site
        </Typography>
        <Typography variant="body1">
          Les présentes Conditions Générales d’Utilisation (CGU) ont pour objet
          de définir les conditions d’accès et d’utilisation du site par les
          utilisateurs.
          <br/><br/>
          Le site recherchecadastrale.fr est édité et hébergé par Codangry, éditeur, gestionnaire et hébergeur du site.
          <br/><br/>
          Recherchecadastrale.fr permet de rechercher et consulter des informations cadastrales à titre informatif.
          Les données (cadastre et adresses) sont issues de l'open data -données publiques-, fournies par Etalab.
          Le rapprochement des adresses et des parcelles cadastrale est réalisé par recherchecadastrale.fr et contient
          des erreurs de part la nature des données.
          L'algorithme de recherche des parcelles est également réalisé par recherchecadastrale.fr.
          <br/><br/>
          Aucune données relatives aux propriétaires foncier n'est connue, stockée ou diffusée.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 2 : Acceptation des CGU
        </Typography>
        <Typography variant="body1">
          En accédant au site, l'utilisateur accepte sans réserve les présentes Conditions Générales d'Utilisation (CGU)
          qui régissent son utilisation.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 3 : Accès au site
        </Typography>
        <Typography variant="body1">
          L’accès au site est libre et gratuit, mais certaines sections
          nécessitent de visualiser des publicités pour être consultées.
          Codangry se réserve le droit de limiter l’accès à certaines sections
          jusqu’à ce que l’utilisateur ait visionné les contenus publicitaires
          requis.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 4 : Utilisation du site
        </Typography>
        <Typography variant="body1">
          L’utilisateur s’engage à utiliser le site de manière conforme à la
          législation en vigueur et à ne pas perturber son bon fonctionnement.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 5 : Protection des données personnelles
        </Typography>

        <Typography sx={{paddingTop: "10px"}} variant="h3">
          1. Collecte et traitement des données personnelles
        </Typography>
        <Typography variant="body1">
          Codangry collecte et traite des données personnelles conformément à la
          législation en vigueur, notamment le RGPD.
          <br/>
          Les données personnelles collectées comprennent :
          <br/>
          - <strong>Adresses IP</strong> : Elles sont temporairement stockées
          pour la régulation de la charge du serveur, avec une durée de
          conservation équivalente à celle de la temporisation (quelques
          secondes en général).
          <br/>
          - <strong>Données de navigation</strong> : Collectées à des fins
          statistiques via Umami, dans une configuration respectueuse de la vie
          privée (sans cookies, données anonymisées).
        </Typography>

        <Typography sx={{paddingTop: "10px"}} variant="h3">
          2. Cookies et technologies similaires
        </Typography>
        <Typography variant="body1">
          Le site utilise des cookies et des technologies similaires pour
          diffuser des publicités via Google AdSense (désactivé pour le moment).
          <br/>
          Google AdSense utilise des cookies pour diffuser des annonces en
          fonction des visites antérieures des utilisateurs sur le site ou sur
          d'autres sites.
          <br/>
          Les utilisateurs peuvent désactiver la personnalisation des annonces
          en visitant les{" "}
          <Link href="https://adssettings.google.com/authenticated">
            Paramètres des annonces Google
          </Link>
          .
        </Typography>

        <Typography sx={{paddingTop: "10px"}} variant="h3">
          3. Utilisation des données par des tiers
        </Typography>
        <Typography variant="body1">
          Les fournisseurs tiers, y compris Google (désactivé pour le moment), utilisent des cookies pour
          diffuser des annonces en fonction des visites antérieures des
          utilisateurs sur le site.
          <br/>
          En utilisant ce site, l'utilisateur consent à la collecte et à
          l'utilisation de ses données par Google conformément à sa{" "}
          <Link href="https://policies.google.com/privacy">
            politique de confidentialité
          </Link>
          .
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 6 : Responsabilité
        </Typography>
        <Typography variant="body1">
          Codangry met tout en œuvre pour garantir la fiabilité des informations
          disponibles sur le site, sans toutefois engager sa responsabilité en cas d'erreurs,
          d'inexactitudes ou de dysfonctionnements.
          <br/><br/>
          De plus, Codangry décline toute responsabilité quant à
          l'utilisation qui pourrait être faite des informations fournies sur le site et des conséquences éventuelles
          de cette utilisation. L'utilisateur est seul responsable de l'usage qu'il
          fait du site, y compris des actions qu'il entreprend sur la base des
          informations consultées ou téléchargées. Codangry ne pourra en aucun
          cas être tenu responsable de tout dommage direct, indirect, matériel ou
          immatériel, de quelque nature qu'il soit, résultant de l'accès au site,
          de son utilisation ou de l'incapacité à y accéder, et ce, que ces dommages
          soient liés à une mauvaise utilisation, une erreur, une omission ou une
          interruption dans le service.
          <br/><br/>
          Les informations fournies sont à titre informatif et ne constituent en aucun cas un conseil
          légal ou professionnel.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 7 : Propriété intellectuelle
        </Typography>
        <Typography variant="body1">
          Tout le contenu du site est protégé par la législation sur la
          propriété intellectuelle. Toute reproduction non autorisée est
          interdite.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 8 : Modification des CGU
        </Typography>
        <Typography variant="body1">
          Codangry se réserve le droit de modifier les présentes CGU à tout
          moment. Les modifications seront mises en ligne sur le site et seront
          applicables dès leur publication.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 9 : Droit applicable
        </Typography>
        <Typography variant="body1">
          Les présentes CGU sont régies par la loi française. En cas de litige,
          les tribunaux français seront compétents.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Article 10 : Exactitude des données
        </Typography>
        <Typography variant="body1">
          Le site recherchecadastrale.fr met à disposition des utilisateurs des
          données cadastrales et adresses postales issues de sources publiques.
          Toutefois, nous ne garantissons pas l'exactitude, l'exhaustivité ou la
          mise à jour de ces informations. Les utilisateurs sont invités à
          utiliser ces données à titre informatif uniquement. En cas d'erreur ou
          d'inexactitude constatée sur les informations cadastrales, nous vous
          invitons à contacter directement le service du cadastre sur le site{" "}
          <Link href="https://www.cadastre.gouv.fr/">cadastre.gouv.fr</Link> afin de
          signaler toute correction ou mise à jour nécessaire, ou sur le site{" "}
          <Link href="https://www.economie.gouv.fr/particuliers/tout-savoir-cadastre-consulter-modifier">economie.gouv.fr</Link> afin de
          consulter la démarche de modification du cadastre.
        </Typography>

        <Typography
          id="mentions-legales"
          sx={{paddingTop: "30px"}}
          variant="h1"
        >
          Mentions Légales
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Éditeur du site
        </Typography>
        <Typography variant="body1">
          Le site web est édité par Codangry, particulier, domicilié en France.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Hébergement
        </Typography>
        <Typography variant="body1">
          Le site est hébergé par Codangry, en auto-hébergement.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Contact
        </Typography>
        <Typography variant="body1">
          Adresse email : codangrydev[arobase]gmail[point]com
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Données personnelles
        </Typography>

        <Typography sx={{paddingTop: "10px"}} variant="h3">
          1. Collecte et traitement des données personnelles
        </Typography>
        <Typography variant="body1">
          Codangry collecte et traite des données personnelles conformément à la
          législation en vigueur, notamment le RGPD.
          <br/>
          Les données personnelles collectées comprennent :
          <br/>
          - <strong>Adresses IP</strong> : Elles sont temporairement conservées
          à des fins de régulation de la charge du serveur, avec une durée de
          conservation équivalente à celle de la temporisation (quelques
          secondes en général).
          <br/>
          - <strong>Données de navigation</strong> : Collectées à des fins
          statistiques via Umami, dans le respect de la vie privée (configuration
          sans cookies, données anonymisées).
        </Typography>

        <Typography sx={{paddingTop: "10px"}} variant="h3">
          2. Publicités et cookies tiers
        </Typography>
        <Typography variant="body1">
          Le site diffuse des publicités via Google AdSense (désactivé pour le moment).
          <br/>
          Les fournisseurs tiers, y compris Google (désactivé pour le moment), utilisent des cookies pour
          diffuser des annonces en fonction des visites antérieures des
          utilisateurs sur ce site ou sur d'autres sites.
          <br/>
          Les utilisateurs peuvent désactiver l'utilisation des cookies par
          Google en visitant les{" "}
          <Link href="https://adssettings.google.com/authenticated">
            Paramètres des annonces Google
          </Link>
          .
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Responsabilité
        </Typography>
        <Typography variant="body1">
          Les informations disponibles sur le site sont fournies à titre
          indicatif et peuvent être modifiées à tout moment. Codangry ne peut
          être tenu responsable d’éventuelles erreurs ou omissions dans les
          informations fournies, ni de l’utilisation qui en est faite par les
          utilisateurs.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Accès aux contenus
        </Typography>
        <Typography variant="body1">
          Certaines parties du site sont accessibles après avoir visualisé des
          publicités. Codangry se réserve le droit de conditionner l'accès à
          certaines sections en fonction de la visualisation de contenus
          sponsorisés ou publicitaires. Les utilisateurs sont informés de ces
          conditions d'accès au moment de la navigation sur les pages
          concernées.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Propriété intellectuelle
        </Typography>
        <Typography variant="body1">
          Le contenu du site, y compris les textes, images et graphiques, est
          protégé par les lois en vigueur sur la propriété intellectuelle. Toute
          reproduction, distribution ou utilisation du contenu sans autorisation
          expresse est interdite.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default MentionsLegalesPopup;

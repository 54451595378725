import TileLayer from "ol/layer/Tile";
import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";

const resolutions = [
  156543.03392804103,
  78271.5169640205,
  39135.75848201024,
  19567.879241005125,
  9783.939620502562,
  4891.969810251281,
  2445.9849051256406,
  1222.9924525628203,
  611.4962262814101,
  305.74811314070485,
  152.87405657035254,
  76.43702828517625,
  38.218514142588134,
  19.109257071294063,
  9.554628535647034,
  4.777314267823517,
  2.3886571339117584,
  1.1943285669558792,
  0.5971642834779396,
  0.29858214173896974,
  0.14929107086948493,
  0.07464553543474241
];

const OrthoLayer = new TileLayer({
  source: new WMTS({
    url: "https://data.geopf.fr/wmts",
    layer: "ORTHOIMAGERY.ORTHOPHOTOS",
    matrixSet: "PM",
    format: "image/jpeg",
    style: "normal",
    tileGrid: new WMTSTileGrid({
      origin: [-20037508, 20037508], // topLeftCorner
      resolutions: resolutions, // résolutions
      matrixIds: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"] // ids des TileMatrix
    })
  })
});

const ParcellesLayer = new TileLayer({
  minZoom: 17,
  source: new WMTS({
    url: "https://data.geopf.fr/wmts",
    layer: "CADASTRALPARCELS.PARCELS",
    matrixSet: "PM",
    format: "image/png",
    style: "bdparcellaire",
    tileGrid: new WMTSTileGrid({
      origin: [-20037508, 20037508], // topLeftCorner
      resolutions: resolutions, // résolutions
      matrixIds: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"] // ids des TileMatrix
    })
  })
});

const SectionsLayer = new TileLayer({
  minZoom: 16,
  source: new WMTS({
    url: "https://data.geopf.fr/wmts",
    layer: "CADASTRAL.PARCELS.SECTIONS",
    matrixSet: "PM",
    format: "image/png",
    style: "normal",
    tileGrid: new WMTSTileGrid({
      origin: [-20037508, 20037508], // topLeftCorner
      resolutions: resolutions, // résolutions
      matrixIds: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"] // ids des TileMatrix
    })
  })
});

const CommunesLayer = new TileLayer({
  maxZoom: 16,
  source: new WMTS({
    url: "https://data.geopf.fr/wmts",
    layer: "ADMINEXPRESS-COG-CARTO.LATEST",
    matrixSet: "PM",
    format: "image/png",
    style: "normal",
    tileGrid: new WMTSTileGrid({
      origin: [-20037508, 20037508], // topLeftCorner
      resolutions: resolutions, // résolutions
      matrixIds: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"] // ids des TileMatrix
    })
  })
});


export {OrthoLayer, ParcellesLayer, SectionsLayer, CommunesLayer}

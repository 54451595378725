import React, {useContext} from "react";
import Map from "./App/Map";
import UpdatingPopup from "./App/UpdatingPopup";
import SearchPopup from "./App/SearchPopup";
import Bottom from "./App/Bottom";
import SearchResultInfo from "./App/SearchResultInfo";
import MentionsLegalesPopup from "./App/MentionsLegalesPopup";
import {AppContext, PAGE_EXPLORATION_COMMUNE} from "./App/context/AppProvider";
import Alert from "@mui/material/Alert";
import {SearchContext} from "./App/context/SearchProvider";
import FAQPopup from "./App/FAQPopup";
import ExploreResultInfo from "./App/ExploreResultInfo";


const App = () => {
  const {
    page,
    updatingPopupOpen,
    searchPopupOpen,
    searchResultInfoOpen,
    exploreResultInfoOpen,
    mentionsLegalesOpen,
    FAQOpen,
    alertMessage,
  } = useContext(AppContext);

  const {
    doSearch
  } = useContext(SearchContext);

  React.useEffect(() => {
    if (page === PAGE_EXPLORATION_COMMUNE) {
      doSearch();
    }
  }, []);

  return <>
    <Map/>
    {updatingPopupOpen && <UpdatingPopup/>}
    {searchPopupOpen && <SearchPopup/>}
    {searchResultInfoOpen && <SearchResultInfo/>}
    {exploreResultInfoOpen && <ExploreResultInfo/>}
    {mentionsLegalesOpen && <MentionsLegalesPopup/>}
    {FAQOpen && <FAQPopup/>}
    <Bottom
      style={{
        position: "absolute",
        bottom: "0px",
        right: 0,
      }}
    />
    {alertMessage && (
      <Alert severity={"error"} sx={{
        position: "absolute",
        top: "20px",
        right: "20px",
        width: "300px"
      }}>
        {alertMessage}
      </Alert>
    )}
  </>
};

export default App;

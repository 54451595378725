import React, {useContext} from "react";
import useTheme from "@mui/material/styles/useTheme";
import {AppContext} from "./context/AppProvider";
import Logo from "./common/Logo";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";


const Bottom = ({style}) => {
  const theme = useTheme();
  const {cadastreVersionDate} = useContext(AppContext);

  const {
    isMobile,
    setMentionsLegalesOpen,
    setFAQOpen,
    bottomHeight
  } = useContext(AppContext)

  return <Paper
    sx={{
      height: bottomHeight + "px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "0px",
      borderTop: isMobile ? "1px solid #AAAAAA" : "none",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      ...style,
    }}>
    {!isMobile && <Logo/>}
    <Box sx={{
      display: "inline",
      fontSize: "80%",
      flexDirection: "row",
      padding: "10px 20px",
    }}>
      <Box variant="body2">
        Données <Link sx={{textDecorationColor: theme.palette.primary.main}} href="https://cadastre.data.gouv.fr/">cadastre.data.gouv.fr</Link> {cadastreVersionDate}
        &nbsp;- Fond <Link sx={{textDecorationColor: theme.palette.primary.main}} href="https://www.ign.fr/">IGN</Link>
        &nbsp;- <Box variant="body2"
                     onClick={() => setMentionsLegalesOpen(true)}
                     sx={{
                       display: "inline",
                       cursor: "pointer",
                       textDecoration: "underline",
                       textDecorationColor: theme.palette.primary.main,
                       "&:hover": {
                         color: theme.palette.primary.main,
                       }
                     }}>
        Mentions légales & CGU
      </Box>
        &nbsp;- <Box variant="body2"
                     onClick={() => setFAQOpen(true)}
                     sx={{
                       display: "inline",
                       cursor: "pointer",
                       textDecoration: "underline",
                       textDecorationColor: theme.palette.primary.main,
                       "&:hover": {
                         color: theme.palette.primary.main,
                       }
                     }}>
        FAQ
      </Box>
      </Box>

    </Box>
  </Paper>
};

export default Bottom;

const communes = [
  {
    name: "Paris",
    coords: [2.3522, 48.8566]
  },
  {
    name: "Marseille",
    coords: [5.3698, 43.2965]
  },
  {
    name: "Lyon",
    coords: [4.8357, 45.764]
  },
  {
    name: "Toulouse",
    coords: [1.4442, 43.6047]
  },
  {
    name: "Nice",
    coords: [7.2661, 43.7034]
  },
  {
    name: "Nantes",
    coords: [-1.5536, 47.2184]
  },
  {
    name: "Strasbourg",
    coords: [7.7521, 48.5734]
  },
  {
    name: "Montpellier",
    coords: [3.8767, 43.6108]
  },
  {
    name: "Bordeaux",
    coords: [-0.5792, 44.8378]
  },
  {
    name: "Lille",
    coords: [3.0573, 50.6293]
  },
  {
    name: "Rennes",
    coords: [-1.6778, 48.1173]
  },
  {
    name: "Reims",
    coords: [4.0317, 49.2583]
  },
  {
    name: "Le Havre",
    coords: [0.1077, 49.4938]
  },
  {
    name: "Saint-Étienne",
    coords: [4.3872, 45.4397]
  },
  {
    name: "Toulon",
    coords: [5.9303, 43.1242]
  },
  {
    name: "Grenoble",
    coords: [5.7245, 45.1885]
  },
  {
    name: "Dijon",
    coords: [5.0415, 47.3225]
  },
  {
    name: "Angers",
    coords: [-0.5614, 47.4784]
  },
  {
    name: "Nîmes",
    coords: [4.3601, 43.8367]
  },
  {
    name: "Villeurbanne",
    coords: [4.8824, 45.7717]
  },
  {
    name: "Clermont-Ferrand",
    coords: [3.0868, 45.7772]
  },
  {
    name: "Le Mans",
    coords: [0.1963, 48.0061]
  },
  {
    name: "Aix-en-Provence",
    coords: [5.4475, 43.5297]
  },
  {
    name: "Brest",
    coords: [-4.4945, 48.3904]
  },
  {
    name: "Tours",
    coords: [0.6863, 47.3941]
  },
  {
    name: "Amiens",
    coords: [2.2967, 49.8942]
  },
  {
    name: "Limoges",
    coords: [1.2611, 45.8487]
  },
  {
    name: "Annecy",
    coords: [6.1289, 45.8992]
  },
  {
    name: "Perpignan",
    coords: [2.8948, 42.6887]
  },
  {
    name: "Metz",
    coords: [6.1757, 49.1193]
  },
  {
    name: "Besançon",
    coords: [6.0249, 47.2378]
  },
  {
    name: "La Rochelle",
    coords: [-1.1536, 46.1591]
  },
  {
    name: "Saint-Malo",
    coords: [-2.0158, 48.6495]
  },
  {
    name: "Biarritz",
    coords: [-1.5586, 43.4832]
  },
  {
    name: "Saint-Tropez",
    coords: [6.6394, 43.2677]
  },
  {
    name: "Ajaccio",
    coords: [8.734, 41.926]
  },
  {
    name: "Saint-Nazaire",
    coords: [-2.2078, 47.2854]
  }
];

const getRandomCommune = (applyRandomOffset) => {
  const randomCity = communes[Math.floor(Math.random() * communes.length)];

  if (!applyRandomOffset) {
    return randomCity.coords;
  } else {
    const offset = [
      (Math.random() - 0.5) * 0.01,
      (Math.random() - 0.5) * 0.01
    ];
    return [
      randomCity.coords[0] + offset[0],
      randomCity.coords[1] + offset[1]
    ];
  }
}

export {communes, getRandomCommune}

import React, {useContext} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {AppContext} from "./context/AppProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Link from "@mui/material/Link";

const FAQPopup = () => {
  const {
    FAQOpen,
    setFAQOpen,
  } = useContext(AppContext);

  return (
    <Dialog
      open={FAQOpen}
      onClose={() => setFAQOpen(false)}
      sx={{
        "& .MuiDialog-paper": {
          width: "800px",
          maxWidth: "80%",
          maxHeight: "80%",
        },
      }}
    >
      <DialogActions>
        <Button
          onClick={() => {
            setFAQOpen(false);
          }}
          sx={{
            height: "40px",
            borderRadius: "20px",
            minWidth: "40px",
          }}
        >
          <FontAwesomeIcon icon={faXmark}/>
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h1">
          Foire Aux Questions
        </Typography>
      </DialogTitle>
      <DialogContent>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Qui fait ce site, pourquoi ?
        </Typography>
        <Typography variant="body1">
          Ce site est créé et hébergé par un particulier dans son garage, et répond à plusieurs objectifs :
          <br/><br/>
          - Mettre à disposition de tous le cadastre français de la manière la plus efficace, simple et rapide
          possible, tout en étant 100% gratuit mais financé par de la publicité imposée.
          <br/>
          - Le défi technique de créer un site web le plus efficient possible, via l'exploitation de technologies
          open sources efficaces comme Postgresql + Postgis, OpenLayers, ReactJS/MUI ou encore PHP.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          À quoi sert ce site web ?
        </Typography>
        <Typography variant="body1">
          <Link href="https://recherchecadastrale.fr">
            recherchecadastrale.fr
          </Link> permets d'<Link href="/cadastre/explorer">explorer</Link> le cadastre d'une commune et de trouver des parcelles
          soit par <Link href="/cadastre/recherche/par_reference_cadastrale">leur référence cadastrale</Link>,
          soit à <Link href="/cadastre/recherche/par_adresse">proximité d'une adresse postale</Link>, soit par
          leur <Link href="/cadastre/recherche/par_surface">contenance</Link>, c'est à dire leur surface.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Qu'est-ce qu'une parcelle divisée ?
        </Typography>
        <Typography variant="body1">
          Il peut arriver que des terrains soient des regroupements de parcelle cadastrale.
          Afin de pouvoir trouver ces terrains et les parcelles qui les composent,
          <Link href="/cadastre/recherche/par_surface">la recherche par surface</Link> propose d'essayer de regrouper les parcelles qui se
          chevauchent et de détecter les agglomérats dont la surface additionnée correspond
          à celle demandée. Néanmoins, aucune forme de contrôle d'appartenance à un même
          terrain n'est réalisée.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          D'où viennent les données ?
        </Typography>
        <Typography variant="body1">
          Les parcelles cadastrales et adresses proviennent d'<Link href="https://cadastre.data.gouv.fr/datasets/cadastre-etalab">etalab de cadastre.data.gouv.fr
        </Link>, les adresses d'<Link href="https://adresse.data.gouv.fr">adresse.data.gouv.fr</Link>.
        </Typography>


        <Typography sx={{paddingTop: "30px"}} variant="h2">
          Est-ce que ce site est fiable ?
        </Typography>
        <Typography variant="body1">
          Malgré tout le soin apporté, il est possible que des erreurs ce soient glissées dans les donnes affichées :
          <br/><br/>
          - Le cadastre et la base d'adresse en eux-même peuvent comporter des erreurs, notamment les géométries comme expliqué <Link href="https://github.com/datagouv/guides.data.gouv.fr/blob/main/reutiliser-des-donnees/autour-du-cadastre/faq-cadastre.md">ici</Link>
          <br/>- La récupération des données par <Link href="https://recherchecadastrale.fr">
          recherchecadastrale.fr
        </Link>, leur traitement et leur croisement peut être source d'erreur.
          <br/><br/>
          <b><Link href="https://recherchecadastrale.fr">
            recherchecadastrale.fr
          </Link> ne garantit en aucun cas la fiabilité des données, ni la disponibilité du site.</b>
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          - Pourquoi les adresses sont parfois approximatives ?
        </Typography>
        <Typography variant="body1">
          Le fichier des adresses est par nature assez approximatif. De plus, chaque adresse est géolocalisée (point)
          et peut se trouver sur une parcelle qui n'est pas celle où se trouve réellement l'adresse postale.
          L'algorithme de croisement des données réalisé par <Link href="https://recherchecadastrale.fr">
          recherchecadastrale.fr</Link> tente de réduire au maximum les erreurs, mais il n'est pas possible,
          en l'état des données opendata disponibles, d'éviter ces approximations.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          - Il y a une erreur dans le cadastre, comment corriger ?
        </Typography>
        <Typography variant="body1">
          Comme expliqué dans la <Link href="https://cadastre.data.gouv.fr/faq">FAQ du cadastre.data.gouv.fr</Link>,
          la demande doit être adressée au centre des impôts fonciers de votre département.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          - Comment trouver le propriétaire d'une parcelle ?
        </Typography>
        <Typography variant="body1">
          <Link href="https://recherchecadastrale.fr">recherchecadastrale.fr</Link> ne possède pas de référentiel
          des propriétaires fonciers. Afin d'avoir l'information, il est nécessaire d'effectuer une demande (payante), voir
          <Link href="https://www.service-public.fr/particuliers/vosdroits/F17759">cette page de service-public.fr</Link>.
        </Typography>

        <Typography sx={{paddingTop: "30px"}} variant="h2">
          - J'ai un problème technique, une demande, une suggestion
        </Typography>
        <Typography variant="body1">
          Toute demande doit être adressée par mail à l'adresse codangrydev[arobase]gmail[point]com.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default FAQPopup;

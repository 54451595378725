import React, {useContext, useRef} from "react";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {MapContext} from "./context/MapProvider";
import ParcelleCard from "./ResultPanel/ParcelleCard";
import {AppContext} from "./context/AppProvider";
import {SearchContext} from "./context/SearchProvider";
import Typography from "@mui/material/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLandmark} from "@fortawesome/free-solid-svg-icons/faLandmark";

const ExploreResultInfo = ({
                            style
                          }) => {
  const theme = useTheme();

  const itemRef = useRef(null);
  const navigationRef = useRef(null);

  const {
    isMobile,
    page,
    bottomHeight,
    isLightTheme
  } = useContext(AppContext)

  const {
    commune,
  } = useContext(SearchContext)

  const {
    selectedFeature,
  } = useContext(MapContext);

  function capitalizeWords(str) {
    const lowercaseWords = ["le", "la", "les", "de", "des", "du", "au", "aux", "en", "sur", "et", "ou"];
    return str
      .toLowerCase()
      .split(" ")
      .map((word, index) =>
        // Capitalize the first word and all words not in lowercaseWords list
        (index === 0 || !lowercaseWords.includes(word))
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word
      )
      .join(" ");
  }

  return <>
    <Paper
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        top: "calc(10px + env(safe-area-inset-top))",
        right: "10px",
        justifySelf: "center",
        padding: isMobile ? "10px" : "20px",
        borderRadius: "20px",
        alignItems: "flex-start",
        width: isMobile ? "auto" : "250px",
        fontSize: isMobile ? "80%" : "100%",
        minWidth: "110px"
      }}
    >
      <FontAwesomeIcon style={{
        position: "absolute",
        top: isMobile ? "10px" : "23px",
        right: isMobile ? "10px" : "23px",
      }} icon={faLandmark}/>

      <Typography sx={{
        fontFamily: "\"Lato\", sans-serif",
        fontSize: "115%",
        paddingRight: "20px"
      }} variant="h1">
        {capitalizeWords(commune.nom)}
      </Typography>
      <Typography sx={{
        fontFamily: "\"Lato\", sans-serif",
        fontSize: "85%"
      }} variant="h2">
        {commune.code_postal}
      </Typography>

      <Typography sx={{
        paddingTop: isMobile ? "10px" : "20px",
        fontFamily: "\"Lato\", sans-serif",
        fontSize: "80%"
      }} variant="h3">
        {commune.nom_region}
      </Typography>
      <Typography sx={{
        fontFamily: "\"Lato\", sans-serif",
        fontSize: "75%"
      }} variant="h4">
        {commune.nom_departement}
      </Typography>
    </Paper>


    {selectedFeature !== null && (
      <Box
        variant="outlined"
        tabIndex={-1}
        elevation={0}
        sx={{
          position: "absolute",
          bottom: bottomHeight + 10 + "px",
          maxWidth: "64%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyItems: "center",
          gap: "10px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "80%",
            width: "100%",
            maxWidth: "100%",
            left: "0%",
            transform: "translate(0%, 0%)",
            gap: "10px",
            bottom: bottomHeight + "px",
          },
          ...style,
        }}
      >
        <Paper
          ref={navigationRef}
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyItems: "center",
            overflowX: "scroll",
            scrollbarWidth: "none",
            borderRadius: "35px",
            padding: (selectedFeature === null ? "10px" : "15px 0px"),
            "&::-webkit-scrollbar": {
              display: "none"
            },
            [theme.breakpoints.down("sm")]: {
              borderRadius: "0px",
            }
          }}>
          <ParcelleCard
            sx={{
              fontSize: isMobile ? "120%" : "100%",
            }}
            hideDelete={true}
            selected={true}
            reference_cadastrale={selectedFeature.getProperties()["reference_cadastrale"]}
            adresse={selectedFeature.getProperties()["adresse"]}
            longitude={selectedFeature.getProperties()["longitude"]}
            latitude={selectedFeature.getProperties()["latitude"]}
            commune={selectedFeature.getProperties()["commune"]}
            surface={selectedFeature.getProperties()["surface"]}
            ref={(el) => (itemRef.current = el)}
          />
        </Paper>
      </Box>
    )}
  </>;
};

export default ExploreResultInfo;

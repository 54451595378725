import React, {createContext, useContext, useState} from "react";
import {AppContext, PAGE_EXPLORATION_COMMUNE} from "./AppProvider";

export const MapContext = createContext();

const MapProvider = ({children}) => {
  const {
    page,
  } = useContext(AppContext);

  const [features, setFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [showCadastreLayer, setShowCadastreLayer] = useState(page === PAGE_EXPLORATION_COMMUNE);

  const toggleCadastreLayer = () => setShowCadastreLayer(!showCadastreLayer);

  return <MapContext.Provider value={{
    features,
    setFeatures,
    selectedFeature,
    setSelectedFeature,
    showCadastreLayer,
    toggleCadastreLayer,
  }}>
    {children}
  </MapContext.Provider>
};

export default MapProvider;

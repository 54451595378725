import React from "react";
import {createRoot} from "react-dom/client";
import "../scss/styles.scss";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {darkTheme, lightTheme} from "./Root/App/common/theme";
import AppProvider from "./Root/App/context/AppProvider";
import MapProvider from "./Root/App/context/MapProvider";
import SearchProvider from "./Root/App/context/SearchProvider";
import App from "./Root/App";
import {Helmet} from "react-helmet";

const Root = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = prefersDarkMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="theme-color" content={prefersDarkMode ? "#000000" : "#f7f7f7"}/>
        <meta name="mobile-web-app-capable" content="yes"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>
      </Helmet>
      <CssBaseline/>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: prefersDarkMode ? "#000000" : "#f7f7f7",
          },
        }}
      />

      <AppProvider>
        <MapProvider>
          <SearchProvider>
            <App/>
          </SearchProvider>
        </MapProvider>
      </AppProvider>
    </ThemeProvider>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<Root/>);

import React from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";


const GoogleStreetViewButton = ({
                                  sx,
                                  longitude,
                                  latitude
                                }) => {
  const theme = useTheme();

  const google_stret_view_link = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}&heading=0`

  return <Button
    target="_blank"
    href={google_stret_view_link}
    sx={{
      ...sx,
      backgroundColor: "primary.contrastText",
      height: "28px",
      width: "28px",
      minWidth: "28px",
      padding: "0px",
      borderRadius: "20px",
      color: "primary.contrastText",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
      "&:active": {
        backgroundColor: theme.palette.primary.main,
      },

    }}
  >
    <Box
      className="backgroundimage-google-streetview"
      sx={{
        position: "relative",
        width: "20px",
        height: "20px",
      }}
    />
  </Button>
};

export default GoogleStreetViewButton;

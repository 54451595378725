import {Fill, Stroke, Style} from "ol/style";

const parcelleStyle = function(theme) {
  return new Style({
    fill: new Fill({
      color: theme.palette.primary.light + "40"
    }),
    stroke: new Stroke({
      color: theme.palette.primary.dark,
      width: 2
    }),
  });
};

const parcelleRegroupeeStyle = function(theme) {
  return new Style({
    stroke: new Stroke({
      color: theme.palette.primary.ultraLight,
      width: 1
    }),
  });
};

const selectedParcelleStyle = function(theme) {
  return new Style({
    fill: new Fill({
      color: theme.palette.tertiary.main + "20"
    }),
    stroke: new Stroke({
      color: theme.palette.tertiary.light,
      width: 3
    }),
  })
};


export {parcelleStyle, parcelleRegroupeeStyle, selectedParcelleStyle};

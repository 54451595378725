import React from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Link from "@mui/material/Link";


const Logo = ({style}) => {
  const theme = useTheme();

  return <Link sx={{
    position: "relative",
    display: "flex",
    textDecoration: "none",
    padding: "10px 0px 10px 50px",
    WebkitFontSmoothing: "auto",
    "&:hover": {
      color: theme.palette.text.secondary,
    },
    ...style,
  }} href="/">
    <Box
      className="backgroundimage-logo"
      sx={{
        width: "30px",
        paddingBottom: "30px",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        flexShrink: 1,
        alignSelf: {
          xs: "flex-start",
          sm: "center",
        },
      }}
    />
    <Box sx={{
      padding: "0 10px",
      alignSelf: "center",
      fontWeight: "bold"
    }}>
      recherchecadastrale.fr
    </Box>
  </Link>
};

export default Logo;

import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";

const Popup = () => {
  const theme = useTheme();

  return <>
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        width: "650px",
        height: "150px",
        borderRadius: "30px",
        alignItems: "center",
        position: "absolute",
        top: "calc(50% - 45px)",
        left: "50%",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          height: "100%",
          borderRadius: "0px",
          flexDirection: "column"
        },
      }}
    >
      <Typography sx={{
        flex: 1,
        alignSelf: "stretch",
        padding: "40px",
      }} variant="h4">
        Une mise à jour des données cadatrales est en cours ! Merci pour votre patience.
      </Typography>
    </Paper>
  </>;
}

export default Popup;
